// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-post-page-postpage-tsx": () => import("./../../../src/components/PostPage/postpage.tsx" /* webpackChunkName: "component---src-components-post-page-postpage-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-example-page-tsx": () => import("./../../../src/pages/example-page.tsx" /* webpackChunkName: "component---src-pages-example-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ledaren-tsx": () => import("./../../../src/pages/ledaren.tsx" /* webpackChunkName: "component---src-pages-ledaren-tsx" */),
  "component---src-pages-nyheter-tsx": () => import("./../../../src/pages/nyheter.tsx" /* webpackChunkName: "component---src-pages-nyheter-tsx" */),
  "component---src-pages-om-oss-tsx": () => import("./../../../src/pages/om-oss.tsx" /* webpackChunkName: "component---src-pages-om-oss-tsx" */)
}

